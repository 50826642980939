import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section">
                    <h3>دسترسی سریع</h3>
                    <ul>
                        <li><Link to="/">صفحه اصلی</Link></li>
                        <li><Link to="/track-order">پیگیری آگهی</Link></li>
                        <li><Link to="/rules">قوانین و مقررات</Link></li>
                        <li><Link to="/faq">سوالات متداول</Link></li>
                        <li><Link to="/affiliate">دعوت به همکاری</Link></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h3>تماس با ما</h3>
                    <ul>
                        <li>تلفن: ۰۲۱-XXXXXXXX</li>
                        <li>ایمیل: info@example.com</li>
                        <li>آدرس: تهران، ...</li>
                        <li>ساعات کاری: شنبه تا چهارشنبه ۸ الی ۱۶</li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h3>درباره ما</h3>
                    <p>
                        سامانه درج آگهی در روزنامه، با هدف تسهیل فرآیند ثبت و انتشار آگهی در معتبرترین روزنامه‌های کشور راه‌اندازی شده است.
                    </p>
                </div>
            </div>

            <div className="footer-bottom">
                <p>
                    کلیه حقوق این سایت محفوظ است. &copy; {new Date().getFullYear()}
                </p>
            </div>
        </footer>
    );
}

export default Footer; 