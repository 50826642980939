import React, { useState } from 'react';
import './Affiliate.css';
import { Link } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

function Affiliate() {
    const [activeTab, setActiveTab] = useState('info');
    const [showForm, setShowForm] = useState(false);
    const [referralCode, setReferralCode] = useState('');
    const [dashboardData, setDashboardData] = useState({
        totalEarnings: 0,
        referrals: []
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formData = {
            name: e.target.elements[0].value,
            phone: e.target.elements[1].value,
            email: e.target.elements[2].value,
            iban: e.target.elements[3].value,
            description: e.target.elements[4].value
        };

        try {
            if (process.env.NODE_ENV === 'development') {
                const newCode = 'REF-' + Math.random().toString(36).substr(2, 9).toUpperCase();
                setReferralCode(newCode);
                setActiveTab('dashboard');
            } else {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/affiliate/register`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });
                const data = await response.json();
                if (data.success) {
                    setReferralCode(data.data.referralCode);
                    setActiveTab('dashboard');
                }
            }
        } catch (error) {
            console.error('Error registering affiliate:', error);
            alert('خطا در ثبت درخواست. لطفا دوباره تلاش کنید.');
        }
    };

    const copyToClipboard = () => {
        const referralLink = `${window.location.origin}/?ref=${referralCode}`;
        navigator.clipboard.writeText(referralLink);
        alert('لینک معرف با موفقیت کپی شد!');
    };

    const referralLink = `${window.location.origin}/?ref=${referralCode}`;

    const handleShare = (platform) => {
        const text = 'با ثبت آگهی از طریق لینک زیر، از تخفیف ویژه بهره‌مند شوید:';
        const url = referralLink;

        switch (platform) {
            case 'telegram':
                window.open(`https://t.me/share/url?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`);
                break;
            case 'whatsapp':
                window.open(`https://wa.me/?text=${encodeURIComponent(text + '\n' + url)}`);
                break;
            case 'email':
                window.open(`mailto:?subject=لینک معرف برای ثبت آگهی&body=${encodeURIComponent(text + '\n' + url)}`);
                break;
            default:
                break;
        }
    };

    return (
        <div className="affiliate-container">
            <div className="affiliate-header">
                <h1>برنامه همکاری در فروش</h1>
                <p>با معرفی مشتری و دریافت پورسانت، کسب درآمد کنید</p>
            </div>

            {!showForm && !referralCode && (
                <div className="affiliate-intro">
                    <div className="intro-stats">
                        <div className="stat-card">
                            <span className="big-percentage">5%</span>
                            <p>پورسانت از هر سفارش</p>
                        </div>
                        <div className="stat-card">
                            <span className="big-number">5ام</span>
                            <p>تسویه حساب هر ماه</p>
                        </div>
                        <div className="stat-card">
                            <span className="big-text">∞</span>
                            <p>بدون محدودیت درآمد</p>
                        </div>
                    </div>
                    <div className="intro-actions">
                        <button className="start-button" onClick={() => setShowForm(true)}>
                            شروع همکاری
                        </button>
                        <Link to="/affiliate/dashboard" className="dashboard-button">
                            ورود به داشبورد
                        </Link>
                    </div>
                </div>
            )}

            {showForm && !referralCode && (
                <div className="affiliate-content">
                    <div className="affiliate-tabs">
                        <button 
                            className={activeTab === 'info' ? 'active' : ''} 
                            onClick={() => setActiveTab('info')}
                        >
                            اطلاعات همکاری
                        </button>
                        <button 
                            className={activeTab === 'form' ? 'active' : ''} 
                            onClick={() => setActiveTab('form')}
                        >
                            فرم ثبت‌نام
                        </button>
                    </div>

                    {activeTab === 'info' && (
                        <>
                            <section className="affiliate-section highlight-section">
                                <h2>مزایای همکاری</h2>
                                <div className="benefits-grid">
                                    <div className="benefit-item">
                                        <span className="benefit-icon">💰</span>
                                        <h4>درآمد جذاب</h4>
                                        <p>کسب درآمد بر اساس عملکرد با سقف نامحدود</p>
                                    </div>
                                    <div className="benefit-item">
                                        <span className="benefit-icon">🎯</span>
                                        <h4>استقلال کاری</h4>
                                        <p>انعطاف‌پذیری در ساعات و نحوه فعالیت</p>
                                    </div>
                                    <div className="benefit-item">
                                        <span className="benefit-icon">📅</span>
                                        <h4>پرداخت منظم</h4>
                                        <p>تسویه حساب در تاریخ 5ام هر ماه</p>
                                    </div>
                                    <div className="benefit-item">
                                        <span className="benefit-icon">🤝</span>
                                        <h4>پشتیبانی کامل</h4>
                                        <p>پشتیبانی فنی و اداری در تمام مراحل</p>
                                    </div>
                                </div>
                            </section>

                            <section className="affiliate-section">
                                <h2>نحوه همکاری</h2>
                                <div className="how-it-works">
                                    <div className="step-item">
                                        <div className="step-number">1</div>
                                        <h4>ثبت نام</h4>
                                        <p>فرم درخواست همکاری را تکمیل کنید</p>
                                    </div>
                                    <div className="step-item">
                                        <div className="step-number">2</div>
                                        <h4>دریافت لینک</h4>
                                        <p>لینک اختصاصی خود را دریافت کنید</p>
                                    </div>
                                    <div className="step-item">
                                        <div className="step-number">3</div>
                                        <h4>اشتراک‌گذاری</h4>
                                        <p>لینک خود را به دیگران معرفی کنید</p>
                                    </div>
                                    <div className="step-item">
                                        <div className="step-number">4</div>
                                        <h4>کسب درآمد</h4>
                                        <p>از هر خرید موفق پورسانت دریافت کنید</p>
                                    </div>
                                </div>
                            </section>

                            <div className="next-step-button">
                                <button onClick={() => setActiveTab('form')}>
                                    تکمیل فرم همکاری
                                </button>
                            </div>
                        </>
                    )}

                    {activeTab === 'form' && (
                        <section className="affiliate-section">
                            <h2>فرم درخواست همکاری</h2>
                            <form className="affiliate-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>نام و نام خانوادگی</label>
                                    <input type="text" required />
                                </div>
                                <div className="form-group">
                                    <label>شماره تماس</label>
                                    <input type="tel" required />
                                </div>
                                <div className="form-group">
                                    <label>ایمیل</label>
                                    <input type="email" required />
                                </div>
                                <div className="form-group">
                                    <label>شماره شبا</label>
                                    <input type="text" placeholder="IR-" required />
                                </div>
                                <div className="form-group">
                                    <label>توضیحات تکمیلی</label>
                                    <textarea rows="4"></textarea>
                                </div>
                                <button type="submit" className="submit-button">
                                    ثبت درخواست و دریافت لینک معرف
                                </button>
                            </form>
                        </section>
                    )}
                </div>
            )}

            {referralCode && (
                <div className="affiliate-content">
                    <div className="affiliate-tabs">
                        <button 
                            className={activeTab === 'dashboard' ? 'active' : ''} 
                            onClick={() => setActiveTab('dashboard')}
                        >
                            داشبورد
                        </button>
                        <button 
                            className={activeTab === 'link' ? 'active' : ''} 
                            onClick={() => setActiveTab('link')}
                        >
                            لینک معرف
                        </button>
                    </div>

                    {activeTab === 'dashboard' && (
                        <section className="affiliate-section dashboard-section">
                            <h2>داشبورد همکاری</h2>
                            <div className="dashboard-stats">
                                <div className="stat-item">
                                    <span className="stat-label">درآمد کل</span>
                                    <span className="stat-value">{dashboardData.totalEarnings.toLocaleString()} تومان</span>
                                </div>
                                <div className="stat-item">
                                    <span className="stat-label">تعداد معرفی‌ها</span>
                                    <span className="stat-value">{dashboardData.referrals.length}</span>
                                </div>
                            </div>

                            {dashboardData.referrals.length > 0 ? (
                                <div className="referrals-list">
                                    <h3>سفارشات معرفی شده</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>تاریخ</th>
                                                <th>مبلغ سفارش</th>
                                                <th>پورسانت</th>
                                                <th>وضعیت</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dashboardData.referrals.map((referral, index) => (
                                                <tr key={index}>
                                                    <td>{referral.date}</td>
                                                    <td>{referral.amount.toLocaleString()} تومان</td>
                                                    <td>{referral.commission.toLocaleString()} تومان</td>
                                                    <td>{referral.status}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p className="no-referrals">
                                    هنوز سفارشی از طریق لینک شما ثبت نشده است.
                                </p>
                            )}
                        </section>
                    )}

                    {activeTab === 'link' && (
                        <section className="affiliate-section referral-link-section">
                            <h2>لینک معرف شما</h2>
                            <div className="referral-tools">
                                <div className="referral-link-container">
                                    <input 
                                        type="text" 
                                        value={referralLink}
                                        readOnly
                                    />
                                    <button onClick={copyToClipboard} className="copy-button">
                                        کپی لینک
                                    </button>
                                </div>
                                
                                <div className="qr-code-container">
                                    <QRCodeCanvas 
                                        value={referralLink}
                                        size={200}
                                        level="H"
                                        includeMargin={true}
                                    />
                                    <button onClick={() => {
                                        const canvas = document.querySelector("canvas");
                                        const link = document.createElement('a');
                                        link.download = 'qr-code.png';
                                        link.href = canvas.toDataURL();
                                        link.click();
                                    }} className="download-qr-button">
                                        دانلود QR کد
                                    </button>
                                </div>

                                <div className="share-buttons">
                                    <h3>اشتراک‌گذاری در:</h3>
                                    <div className="share-options">
                                        <button onClick={() => handleShare('telegram')} className="share-button telegram">
                                            <span>تلگرام</span>
                                        </button>
                                        <button onClick={() => handleShare('whatsapp')} className="share-button whatsapp">
                                            <span>واتساپ</span>
                                        </button>
                                        <button onClick={() => handleShare('email')} className="share-button email">
                                            <span>ایمیل</span>
                                        </button>
                                    </div>
                                </div>

                                <div className="marketing-tips">
    <h3>پیشنهادات بازاریابی</h3>
    <ul>
        <li>QR کد را پرینت کرده و در محل  خود نصب کنید</li>
        <li>لینک را در کانال‌ها و گروه‌های مرتبط به اشتراک بگذارید</li>
        <li>از QR کد در کارت ویزیت خود استفاده کنید</li>
        <li>لینک را در شبکه‌های اجتماعی خود قرار دهید</li>
        <li>در گروه‌های تلگرامی کسب و کار فعالیت کنید</li>
        <li>با دفاتر اسناد رسمی و وکلا همکاری کنید</li>
        <li>با مشاورین املاک برای آگهی‌های مربوطه همکاری کنید</li>
        <li>در نمایشگاه‌های تجاری و رویدادهای مرتبط شرکت کنید</li>
        <li>با شرکت‌های استخدامی برای آگهی‌های کاریابی همکاری کنید</li>
        <li>با انجمن‌های صنفی و اتحادیه‌ها ارتباط برقرار کنید</li>
        <li>در سمینارها و همایش‌های تجاری حضور پیدا کنید</li>
        <li>با حسابداران و مشاوران مالی برای آگهی‌های قانونی همکاری کنید</li>
        <li>در وبسایت شخصی یا شرکتی خود لینک معرف را قرار دهید</li>
        <li>از ایمیل مارکتینگ برای معرفی خدمات استفاده کنید</li>
        <li>بروشور تبلیغاتی با QR کد اختصاصی خود تهیه کنید</li>
    </ul>
</div>
                            </div>
                        </section>
                    )}
                </div>
            )}
        </div>
    );
}

export default Affiliate; 