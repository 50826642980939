import React, { useState, useEffect } from 'react';
import './AdminReferralSummary.css';

function AdminReferralSummary() {
    const [summaryData, setSummaryData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7));

    useEffect(() => {
        fetchSummary();
    }, []);

    const fetchSummary = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_WORKER_URL}/api/referral/monthly-summary`);
            const data = await response.json();
            if (data.success) {
                setSummaryData(data.data);
            }
        } catch (error) {
            console.error('Error fetching summary:', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePrint = () => {
        const unpaidReferrers = summaryData.filter(
            referrer => referrer.paymentStatus?.[selectedMonth] !== 'paid' && 
            referrer.earnings?.[selectedMonth] > 0
        );

        // Create printable content
        const printContent = document.createElement('div');
        printContent.innerHTML = `
            <div class="print-header">
                <h2>لیست پرداخت پورسانت معرف‌ها</h2>
                <p>ماه: ${selectedMonth}</p>
                <p>تاریخ پرداخت: 5ام ماه</p>
            </div>
            <table class="print-table">
                <thead>
                    <tr>
                        <th>نام</th>
                        <th>شماره کارت</th>
                        <th>مبلغ (تومان)</th>
                        <th>امضا</th>
                    </tr>
                </thead>
                <tbody>
                    ${unpaidReferrers.map(referrer => `
                        <tr>
                            <td>${referrer.name}</td>
                            <td>${referrer.cardNumber || '_______________'}</td>
                            <td>${(referrer.earnings[selectedMonth] || 0).toLocaleString()}</td>
                            <td>_______________</td>
                        </tr>
                    `).join('')}
                    <tr class="total-row">
                        <td colspan="2">جمع کل:</td>
                        <td>${unpaidReferrers.reduce((sum, ref) => sum + (ref.earnings[selectedMonth] || 0), 0).toLocaleString()}</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        `;

        // Print the content
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>لیست پرداخت پورسانت</title>');
        printWindow.document.write(`
            <style>
                @font-face {
                    font-family: 'IRANSans';
                    src: url('/fonts/IRANSans.ttf') format('truetype');
                }
                body { 
                    font-family: 'IRANSans', Tahoma, Arial; 
                    direction: rtl;
                    padding: 20px;
                }
                .print-header {
                    text-align: center;
                    margin-bottom: 30px;
                }
                .print-table {
                    width: 100%;
                    border-collapse: collapse;
                }
                .print-table th, .print-table td {
                    border: 1px solid #000;
                    padding: 10px;
                    text-align: center;
                }
                .total-row {
                    font-weight: bold;
                }
            </style>
        `);
        printWindow.document.write('</head><body>');
        printWindow.document.write(printContent.innerHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    if (loading) return <div>در حال بارگذاری...</div>;

    return (
        <div className="admin-summary">
            <div className="summary-header">
                <h2>خلاصه عملکرد معرف‌ها</h2>
                <div className="summary-actions">
                    <select 
                        value={selectedMonth} 
                        onChange={(e) => setSelectedMonth(e.target.value)}
                    >
                        {Array.from({ length: 12 }, (_, i) => {
                            const date = new Date();
                            date.setMonth(date.getMonth() - i);
                            const monthStr = date.toISOString().slice(0, 7);
                            return (
                                <option key={monthStr} value={monthStr}>
                                    {monthStr}
                                </option>
                            );
                        })}
                    </select>
                    <button onClick={handlePrint} className="print-button">
                        پرینت لیست پرداخت
                    </button>
                </div>
            </div>

            <div className="summary-table-container">
                <table className="summary-table">
                    <thead>
                        <tr>
                            <th>نام معرف</th>
                            <th>کد معرف</th>
                            <th>شماره کارت</th>
                            <th>تعداد معرفی ماه جاری</th>
                            <th>درآمد ماه جاری</th>
                            <th>کل معرفی‌ها</th>
                            <th>کل درآمد</th>
                            <th>وضعیت پرداخت</th>
                        </tr>
                    </thead>
                    <tbody>
                        {summaryData?.map(referrer => (
                            <tr key={referrer.referralCode}>
                                <td>{referrer.name}</td>
                                <td>{referrer.referralCode}</td>
                                <td>{referrer.cardNumber || '---'}</td>
                                <td>{referrer.currentMonthReferrals}</td>
                                <td>{referrer.currentMonthEarnings.toLocaleString()} تومان</td>
                                <td>{referrer.totalReferrals}</td>
                                <td>{referrer.totalEarnings.toLocaleString()} تومان</td>
                                <td>
                                    {referrer.paymentStatus?.[selectedMonth] === 'paid' 
                                        ? 'پرداخت شده' 
                                        : 'در انتظار پرداخت'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdminReferralSummary; 