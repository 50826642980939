import React from 'react';
import './Rules.css';

function Rules() {
    return (
        <div className="rules-container">
            <h1>قوانین و مقررات</h1>
            
            <div className="rules-content">
                <section className="rules-section">
                    <h2>شرایط عمومی</h2>
                    <ul>
                        <li>مسئولیت صحت محتوای آگهی بر عهده آگهی‌دهنده می‌باشد.</li>
                        <li>محتوای آگهی نباید مغایر با قوانین جمهوری اسلامی ایران باشد.</li>
                        <li>پس از تأیید و پرداخت هزینه، امکان ویرایش یا لغو آگهی وجود ندارد.</li>
                        <li>در صورت عدم تأیید آگهی، هزینه پرداختی به صورت کامل عودت داده می‌شود.</li>
                    </ul>
                </section>

                <section className="rules-section">
                    <h2>شرایط پرداخت</h2>
                    <ul>
                        <li>پرداخت هزینه آگهی به صورت آنلاین و از طریق درگاه‌های معتبر بانکی انجام می‌شود.</li>
                        <li>قیمت‌ها بر اساس تعرفه‌های مصوب روزنامه‌ها تعیین می‌شود.</li>
                        <li>در صورت درخواست نسخه چاپی، هزینه اضافی دریافت می‌گردد.</li>
                    </ul>
                </section>

                <section className="rules-section">
                    <h2>زمان انتشار</h2>
                    <ul>
                        <li>آگهی‌ها معمولاً در اولین نوبت چاپ پس از تأیید منتشر می‌شوند.</li>
                        <li>در صورت حجم بالای آگهی‌ها، ممکن است انتشار با تأخیر یک روزه مواجه شود.</li>
                        <li>روزهای تعطیل رسمی در زمان انتشار محاسبه نمی‌شوند.</li>
                    </ul>
                </section>

                <section className="rules-section">
                    <h2>محدودیت‌ها</h2>
                    <ul>
                        <li>آگهی‌های سیاسی نیاز به مجوزهای خاص دارند.</li>
                        <li>تبلیغات دارویی و پزشکی باید دارای تأییدیه وزارت بهداشت باشند.</li>
                        <li>درج آگهی‌های مرتبط با مسائل قضایی نیازمند ارائه مستندات است.</li>
                    </ul>
                </section>
            </div>
        </div>
    );
}

export default Rules; 