import React, { useState } from 'react';
import './OrderTracking.css';

function OrderTracking() {
    const [refId, setRefId] = useState('');
    const [orderStatus, setOrderStatus] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        
        // For testing purposes, we'll use mock data
        if (refId.startsWith('TEST-')) {
            setOrderStatus({
                status: 'در حال انتشار',
                refId: refId,
                date: new Date().toLocaleDateString('fa-IR'),
                newspaper: 'روزنامه اطلاعات',
                publishDate: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).toLocaleDateString('fa-IR'), // 2 days from now
                details: 'آگهی شما در تاریخ مشخص شده منتشر خواهد شد.'
            });
        } else {
            setOrderStatus('not-found');
        }
    };

    return (
        <div className="tracking-container">
            <h2>پیگیری وضعیت آگهی</h2>
            
            <form onSubmit={handleSubmit} className="tracking-form">
                <div className="form-group">
                    <label>شماره پیگیری:</label>
                    <input 
                        type="text" 
                        value={refId}
                        onChange={(e) => setRefId(e.target.value)}
                        placeholder="مثال: TEST-kgobcpga6"
                        required
                    />
                </div>
                <button type="submit">جستجو</button>
            </form>

            {orderStatus && orderStatus !== 'not-found' && (
                <div className="order-status">
                    <h3>وضعیت آگهی</h3>
                    <div className="status-details">
                        <div className="status-item">
                            <strong>شماره پیگیری:</strong>
                            <span>{orderStatus.refId}</span>
                        </div>
                        <div className="status-item">
                            <strong>وضعیت:</strong>
                            <span>{orderStatus.status}</span>
                        </div>
                        <div className="status-item">
                            <strong>روزنامه:</strong>
                            <span>{orderStatus.newspaper}</span>
                        </div>
                        <div className="status-item">
                            <strong>تاریخ ثبت:</strong>
                            <span>{orderStatus.date}</span>
                        </div>
                        <div className="status-item">
                            <strong>تاریخ انتشار:</strong>
                            <span>{orderStatus.publishDate}</span>
                        </div>
                        <div className="status-message">
                            {orderStatus.details}
                        </div>
                    </div>
                </div>
            )}

            {orderStatus === 'not-found' && (
                <div className="error-message">
                    آگهی با این شماره پیگیری یافت نشد.
                </div>
            )}
        </div>
    );
}

export default OrderTracking; 