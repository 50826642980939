import React, { useState } from 'react';
import './FAQ.css';

function FAQ() {
    const [openItem, setOpenItem] = useState(null);

    const faqItems = [
        {
            question: "چگونه می‌توانم آگهی خود را پیگیری کنم؟",
            answer: "با استفاده از شماره پیگیری که پس از پرداخت موفق دریافت می‌کنید، می‌توانید از طریق بخش 'پیگیری آگهی' در سایت، وضعیت آگهی خود را مشاهده نمایید."
        },
        {
            question: "چه زمانی آگهی من منتشر می‌شود؟",
            answer: "معمولاً آگهی‌ها در اولین نوبت چاپ پس از تأیید و پرداخت منتشر می‌شوند. تاریخ دقیق انتشار پس از پرداخت به شما اعلام خواهد شد."
        },
        {
            question: "آیا امکان ویرایش آگهی پس از پرداخت وجود دارد؟",
            answer: "خیر، پس از تأیید نهایی و پرداخت هزینه، امکان ویرایش آگهی وجود ندارد. لطفاً قبل از پرداخت، اطلاعات را با دقت بررسی نمایید."
        },
        {
            question: "هزینه درج آگهی چگونه محاسبه می‌شود؟",
            answer: "هزینه بر اساس نوع آگهی، روزنامه انتخابی و درخواست نسخه چاپی محاسبه می‌شود. قیمت نهایی قبل از پرداخت به شما نمایش داده خواهد شد."
        },
        {
            question: "در صورت انصراف، هزینه پرداختی قابل برگشت است؟",
            answer: "در صورت عدم تأیید آگهی توسط روزنامه، هزینه به صورت کامل عودت داده می‌شود. اما پس از تأیید آگهی، امکان انصراف و برگشت وجه وجود ندارد."
        },
        {
            question: "چه مدارکی برای درج آگهی نیاز است؟",
            answer: "مدارک مورد نیاز بسته به نوع آگهی متفاوت است. برای آگهی‌های حقوقی و رسمی، ممکن است نیاز به ارائه مستندات باشد."
        },
        {
            question: "آیا امکان درج آگهی فوری وجود دارد؟",
            answer: "بله، برای برخی از انواع آگهی‌ها امکان درج فوری با پرداخت هزینه اضافی وجود دارد. این گزینه در صورت امکان‌پذیر بودن، در زمان ثبت سفارش نمایش داده می‌شود."
        }
    ];

    const toggleItem = (index) => {
        setOpenItem(openItem === index ? null : index);
    };

    return (
        <div className="faq-container">
            <h1>سوالات متداول</h1>
            
            <div className="faq-content">
                {faqItems.map((item, index) => (
                    <div 
                        key={index} 
                        className={`faq-item ${openItem === index ? 'open' : ''}`}
                        onClick={() => toggleItem(index)}
                    >
                        <div className="faq-question">
                            <span>{item.question}</span>
                            <span className="faq-icon">{openItem === index ? '−' : '+'}</span>
                        </div>
                        <div className="faq-answer">
                            {item.answer}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ; 