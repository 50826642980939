import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import MainForm from './components/MainForm';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentError from './components/PaymentError';
import OrderTracking from './components/OrderTracking';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';
import { ToastProvider } from './context/ToastContext';
import ScrollToTop from './components/ScrollToTop';
import './App.css';
import Rules from './components/Rules';
import FAQ from './components/FAQ';
import Affiliate from './components/Affiliate';
import AffiliateDashboard from './components/AffiliateDashboard';
import AdminReferralSummary from './components/AdminReferralSummary';

// Create a layout component that includes the footer
function Layout({ children }) {
    return (
        <div className="app-wrapper">
            <ScrollToTop />
            <div className="app-content">
                {children}
            </div>
            <Footer />
        </div>
    );
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout><MainForm /></Layout>,
    },
    {
        path: "/payment/success",
        element: <Layout><PaymentSuccess /></Layout>,
    },
    {
        path: "/payment/error",
        element: <Layout><PaymentError /></Layout>,
    },
    {
        path: "/track-order",
        element: <Layout><OrderTracking /></Layout>,
    },
    {
        path: "/rules",
        element: <Layout><Rules /></Layout>,
    },
    {
        path: "/faq",
        element: <Layout><FAQ /></Layout>,
    },
    {
        path: "/affiliate",
        element: <Layout><Affiliate /></Layout>,
    },
    {
        path: "/affiliate/dashboard",
        element: <Layout><AffiliateDashboard /></Layout>,
    },
    {
        path: "/admin/referrals",
        element: <Layout><AdminReferralSummary /></Layout>,
    }
]);

function App() {
    return (
        <ErrorBoundary>
            <ToastProvider>
                <RouterProvider router={router} />
            </ToastProvider>
        </ErrorBoundary>
    );
}

export default App; 