import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AffiliateDashboard.css';

function AffiliateDashboard() {
    const [isLogin, setIsLogin] = useState(true); // true for login, false for code recovery
    const [referralCode, setReferralCode] = useState('');
    const [email, setEmail] = useState('');
    const [dashboardData, setDashboardData] = useState(null);

    const handleLogin = async (e) => {
        e.preventDefault();
        // For development, simulate login
        if (process.env.NODE_ENV === 'development') {
            setDashboardData({
                totalEarnings: 850000,
                referrals: [
                    {
                        date: '1402/12/15',
                        amount: 500000,
                        commission: 25000,
                        status: 'پرداخت شده'
                    },
                    {
                        date: '1402/12/20',
                        amount: 800000,
                        commission: 40000,
                        status: 'در انتظار پرداخت'
                    }
                ]
            });
        }
    };

    const handleRecovery = async (e) => {
        e.preventDefault();
        alert('کد معرف به ایمیل شما ارسال شد');
        setIsLogin(true);
    };

    if (dashboardData) {
        return (
            <div className="affiliate-dashboard">
                <h2>داشبورد همکاری</h2>
                <div className="dashboard-stats">
                    <div className="stat-item">
                        <span className="stat-label">درآمد کل</span>
                        <span className="stat-value">{dashboardData.totalEarnings.toLocaleString()} تومان</span>
                    </div>
                    <div className="stat-item">
                        <span className="stat-label">تعداد معرفی‌ها</span>
                        <span className="stat-value">{dashboardData.referrals.length}</span>
                    </div>
                </div>

                <div className="referrals-list">
                    <h3>سفارشات معرفی شده</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>تاریخ</th>
                                <th>مبلغ سفارش</th>
                                <th>پورسانت</th>
                                <th>وضعیت</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dashboardData.referrals.map((referral, index) => (
                                <tr key={index}>
                                    <td>{referral.date}</td>
                                    <td>{referral.amount.toLocaleString()} تومان</td>
                                    <td>{referral.commission.toLocaleString()} تومان</td>
                                    <td>{referral.status}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <div className="affiliate-login">
            <div className="login-container">
                <div className="login-tabs">
                    <button 
                        className={isLogin ? 'active' : ''} 
                        onClick={() => setIsLogin(true)}
                    >
                        ورود به داشبورد
                    </button>
                    <button 
                        className={!isLogin ? 'active' : ''} 
                        onClick={() => setIsLogin(false)}
                    >
                        بازیابی کد معرف
                    </button>
                </div>

                {isLogin ? (
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label>کد معرف</label>
                            <input 
                                type="text" 
                                value={referralCode}
                                onChange={(e) => setReferralCode(e.target.value)}
                                placeholder="مثال: REF-ABC123"
                                required
                            />
                        </div>
                        <button type="submit">ورود به داشبورد</button>
                    </form>
                ) : (
                    <form onSubmit={handleRecovery}>
                        <div className="form-group">
                            <label>ایمیل ثبت‌نامی</label>
                            <input 
                                type="email" 
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="example@email.com"
                                required
                            />
                        </div>
                        <button type="submit">بازیابی کد معرف</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default AffiliateDashboard; 